import * as React from "react";
import { Heading, SimpleGrid, GridItem, Box } from "@chakra-ui/react";
import DocExample from "../../components/docexample";

const DocumentsGrid = React.forwardRef(({ data }, ref) => {
  return (
    <Box w="full" mb="6.25rem" ref={ref}>
      <Heading as="h2" fontSize="2xl" mb="3.125rem" textAlign="center">
        {data.documentTitle}
      </Heading>
      <SimpleGrid
        w="full"
        columns={{
          base: 1,
          md: 2,
          lg: 3,
        }}
        spacing="6"
      >
        {data.documents?.map((document, idx) => (
          <DocExample
            key={`doc-${idx}`}
            href={document.link}
            href2={document.link2}
            title={document.documentName}
            downloadButton={document.downloadButtonText}
            downloadButton2={document.downloadButtonText2}
            seeFilledFormButton={document.seeFilledFormButton}
            downloadIcon={document.downloadIcon}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
});
export default DocumentsGrid;
