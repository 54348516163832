import React, { useEffect, useState } from "react";
import {
  Heading,
  Stack,
  HStack,
  Box,
  Select,
  useRadioGroup,
} from "@chakra-ui/react";
import Accordion from "../../components/accordion";
import RadioCard from "../RadioCard";
import RichText from "../PrismicRichText";

const getFilteredItems = (data, filters) =>
  data.filter((row) => row.year === filters.year && row.type === filters.type);

const ExaminatedCases = React.forwardRef(({ examinatedCases }, ref) => {
  const options = examinatedCases.options;
  const [filters, setFilters] = useState({
    year: examinatedCases?.years[0],
    type: options[0],
  });
  const [items, setItems] = useState([]);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: options[0],
    onChange: (value) =>
      setFilters((oldFilter) => ({ ...oldFilter, type: value })),
  });
  const group = getRootProps();

  useEffect(() => {
    setItems(getFilteredItems(examinatedCases?.show_data, filters));
  }, [filters]);

  return (
    <Box mb="2rem">
      <Heading fontSize="2xl" as="h2" mb="1.875rem" ref={ref}>
        {examinatedCases.examinatedCasesTitle}
      </Heading>
      <Box maxW="60.75rem">
        <Box fontSize="sm" color="grey.100" mb="2rem">
          <RichText text={examinatedCases.examinatedCasesDescription} />
        </Box>
      </Box>
      <Stack
        mb="4"
        mt="2rem"
        direction={["column", "column", "row"]}
        {...group}
      >
        {examinatedCases.service_types?.map((item) => {
          const value = item.type_value;
          const radio = getRadioProps({ value });
          return (
            <RadioCard key={item.type} {...radio}>
              {item.type}
            </RadioCard>
          );
        })}
      </Stack>
      <HStack mb={4}>
        <Select
          w="173px"
          height="43px"
          variant="outline"
          boxShadow="md"
          _focus={{ borderColor: "#006c49", boxShadow: " 0 0 0 1px #006c49" }}
          onChange={(e) =>
            setFilters((oldFilters) => ({
              ...oldFilters,
              year: e.target.value,
            }))
          }
        >
          {examinatedCases.years?.map((year, idx) => (
            <option key={`item-${year}-${idx}`} value={year}>
              {year}
            </option>
          ))}
        </Select>
      </HStack>
      {items?.map((data, idx) => (
        <Accordion
          key={`question-${data.title}-${idx}`}
          question={data?.title}
          answer={data?.description}
        />
      ))}
    </Box>
  );
});
export default ExaminatedCases;
