import React, {useEffect, useState} from 'react';
import { Box,
   Text,
   Heading,
   Flex,
   Container,
   VStack,
  } from '@chakra-ui/react';
import { Link } from 'gatsby';
import RichText from "../PrismicRichText";
const LegalActs = React.forwardRef(({ data },ref) => {
    const legalActs = data.legalActs;
    const [acts, setActs] = useState([]);
    const [id, setId] = useState(null);
    const showActs = (id) => {
        const acts = data.legalActs.find(item => item.id === id)
        setId(id)
        setActs(acts?.items)
    }

    useEffect(() => {
        showActs(legalActs[0]?.id)
    }, [])

  return (
    <Box w="full" mb="6.25rem" ref={ref}>
      <Heading as="h2" fontSize="2xl" mb="2rem" textAlign="center">
       {data.legalActsTitle}
      </Heading>
      <Box bg="green.100">
        <Container px="5" maxW="7xl">
          <Flex
            py="1.875rem"
            direction={{base: 'column', sm: 'row'}}
            justifyContent="space-between"
            alignItems={{base: 'flex-start', sm: "center"}}>
              <VStack mb={{base: '7', sm: '0'}} spacing="2" alignItems="flex-start">
                  {legalActs?.map((item) => (
                      <Text
                          key={`legal-acts-${item.id}`}
                          fontSize={id === item.id ? "2xl" : "xl"}
                            color={id === item.id ? "black" : "white"}
                            backgroundColor={id === item.id ? "white" : ""}
                            padding={id === item.id ? "8px" : ""}
                            borderRadius={id === item.id ? "8px" : ""}
                            cursor="pointer"
                            onClick={() => showActs(item.id)}>
                          {item.title}
                      </Text>
                  ))}
              </VStack>
                  <Box
                  h="480px"
                  maxW="528px"
                  minW="528px"
                  overflowY="auto"
                  bg="white"
                  fontSize="sm"
                  p="2rem"
                  borderRadius="16px"
              >
                  {acts?.map((item, idx) => (
                      <Text key={`law-${item.id}-${idx}`} as={Link} to={item.link} color="black" target="_blank" _hover={{ color: "green.200", textDecoration: "underline" }} >
                          <RichText text={item.name}/> <br/>
                      </Text>
                  ))}
              </Box>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
});

export default LegalActs;
