import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";

const usePrismicFinancialOrganizationsList = () => {
  const { language } = useI18next();
  const newLang =
    language === "en" ? "en-us" : language === "ru" ? "ru" : "hy-am";

  const response = useStaticQuery(graphql`
    query usePrismicFinancialOrganizationsList {
        allPrismicListOfTheFinancialOrganizations(
        filter: { tags: { in: "financial_org_list" } }
      ) {
        nodes {
          lang
          data {
 
           
            banktitle {
              text
            }
            bankdescription {
              richText
            }
        
       
            banks1 {
              name {
                richText
              }
              link {
                url
              }
            }
            bureau {
              name {
                richText
              }
              link {
                url
              }
            }
            credit {
              name {
                richText
              }
              link {
                url
              }
            }
            foreign {
              name {
                richText
              }
              link {
                url
              }
            }
           
            insurance_company {
              name {
                richText
              }
              link {
                url
              }
            }
            investment {
              name {
                richText
              }
              link {
                url
              }
            }
            link_bank {
              url
            }
            link_bureau {
              url
            }
            link_credit {
              url
            }
            link_foreign {
              url
            }
            
            link_insurance_company {
              url
            }
            link_investment {
              url
            }
            link_pawn_shop {
              url
            }
            link_pensions {
              url
            }
            link_persons_carrying {
              url
            }
            link_settlement_services {
              url
            }
            pawn_shop {
              name {
                richText
              }
              link {
                url
              }
            }
            pensions {
              name {
                richText
              }
              link {
                url
              }
            }
            persons_carrying {
              name {
                richText
              }
              link {
                url
              }
            }
            settlement_services {
              name {
                richText
              }
              link {
                url
              }
            }
            title_bank {
              text
            }
            title_bureau {
              text
            }
            title_credit {
              text
            }
            title_foreign {
              text
            }
        
            title_insurance_company {
              text
            }
            title_investment {
              text
            }
            title_pawn_shop {
              text
            }
            title_pensions {
              text
            }
            title_persons_carrying {
              text
            }
            title_settlement_services {
              text
            }
          }
        }
      }
    }
  `);
  return sanitize(
    response.allPrismicListOfTheFinancialOrganizations.nodes.filter(
      (item) => item.lang === newLang
    )[0].data
  );
};

const sanitize = (response) => {
  return {
    bankDescription: response.bankdescription.richText,
    bankTitle: response.banktitle.text,
  
    financialOrganizations: [
      {
        title: response.title_bank.text,
        link: response.link_bank.url,
        items: response.banks1.map((bank) => ({
          name: bank.name.richText,
          href: bank.link.url,
        })),
      },
      {
        title: response.title_insurance_company.text,
        link: response.link_insurance_company.url,
        items: response.insurance_company.map((bank) => ({
          name: bank.name.richText,
          href: bank.link.url,
        })),
      },
      {
        title: response.title_persons_carrying.text,
        link: response.link_persons_carrying.url,
        items: response.persons_carrying.map((bank) => ({
          name: bank.name.richText,
          href: bank.link.url,
        })),
      },
      {
        title: response.title_settlement_services.text,
        link: response.link_settlement_services.url,
        items: response.settlement_services.map((bank) => ({
          name: bank.name.richText,
          href: bank.link.url,
        })),
      },
      {
        title: response.title_foreign.text,
        link: response.link_foreign.url,
        items: response.foreign.map((bank) => ({
          name: bank.name.richText,
          href: bank.link.url,
        })),
      },
      {
        title: response.title_pawn_shop.text,
        link: response.link_pawn_shop.url,
        items: response.pawn_shop.map((bank) => ({
          name: bank.name.richText,
          href: bank.link.url,
        })),
      },
      {
        title: response.title_investment.text,
        link: response.link_investment.url,
        items: response.investment.map((bank) => ({
          name: bank.name.richText,
          href: bank.link.url,
        })),
      },
      {
        title: response.title_pensions.text,
        link: response.link_pensions.url,
        items: response.pensions.map((bank) => ({
          name: bank.name.richText,
          href: bank.link.url,
        })),
      },
      {
        title: response.title_credit.text,
        link: response.link_credit.url,
        items: response.credit.map((bank) => ({
          name: bank.name.richText,
          href: bank.link.url,
        })),
      },
      {
        title: response.title_bureau.text,
        link: response.link_bureau.url,
        items: response.bureau.map((bank) => ({
          name: bank.name.richText,
          href: bank.link.url,
        })),
      },
  
      // {
      //   title: response.title_insurance_broker.text,
      //   link: response.link_insurance_broker.url,
      //   items: response.insurance_broker.map((bank) => ({
      //     name: bank.name.richText,
      //     href: bank.link.url,
      //   })),
      // },
      //query
      // title_insurance_broker {
      //   text
      // }
      // link_insurance_broker {
      //   url
      // }
      // insurance_broker {
      //   name {
      //     richText
      //   }
      //   link {
      //     url
      //   }
      // }
 
   
   
     
    
  
    ],
  };
};

export default usePrismicFinancialOrganizationsList;
