import React from "react";
import { useRadio, Box } from "@chakra-ui/react"
const RadioCard = (props) => {
    const {getInputProps, getCheckboxProps} = useRadio(props)
    const input = getInputProps()
    const checkbox = getCheckboxProps()

    return (
        <Box as="label">
            <input {...input} />
            <Box
                {...checkbox}
                cursor="pointer"
                borderWidth="1px"
                borderRadius="md"
                boxShadow='md'
                _checked={{
                    bg: 'green.200',
                    color: 'white',
                    borderColor: 'green.200',
                }}
                _focus={{
                    boxShadow: 'none',
                }}
                px={5}
                py={2}
            >
                {props.children}
            </Box>
        </Box>
    )

}

export default RadioCard