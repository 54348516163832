import React, {useEffect, useState} from 'react';
import {
    Box,
    Heading, Stack,
    useRadioGroup,
} from '@chakra-ui/react';
import RichText from "../PrismicRichText";
import RadioCard from "../RadioCard";
import Accordion from "../accordion";
const getFilteredItems = (data,filters) =>
    data.filter(row => row.type === filters.type);
const BusinessCirculation = React.forwardRef(( { data }, ref ) => {
    const options = data.options
    const [filters, setFilters] = useState({
        type: options[0]
    });
    const [items, setItems] = useState([]);
    const {getRootProps, getRadioProps} = useRadioGroup({
        name: 'framework',
        defaultValue: options[0],
        onChange: (value) => setFilters(oldFilter => ({...oldFilter, type: value})),
    })
    const group = getRootProps()
    useEffect(() => {
        setItems(getFilteredItems(data?.links, filters))
    }, [filters])

    return (
        <Box mb="15rem" ref={ref}>
            <Heading as="h2" fontSize="2xl" mb="1.875rem">
                {data.businessCirculationTitle}
            </Heading>
            <Box maxW="60.75rem">
                <Box fontSize="sm" color="grey.100" mb="2rem">
                    <RichText text={data.businessCirculationDescription}/>
                </Box>
            </Box>
            <Stack mb="4" mt="2rem" direction={['column', 'column', 'row']} {...group}>
                {data.types?.map((item) => {
                    const value = item.type_value
                    const radio = getRadioProps({value})
                    return (
                        <RadioCard key={item.type} {...radio}>
                            {item.type}
                        </RadioCard>
                    )
                })}
            </Stack>
            {
                items?.map((data, idx) => (
                    <Accordion
                        key={`question-${data.title}-${idx}`}
                        question={data?.title}
                        answer={data?.description}/>
                ))
            }
        </Box>
    );
});

export default BusinessCirculation;
