import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next'

const usePrismicexaminatedCases = () => {
    const { language } = useI18next()
    const newLang = language === 'en' ? 'en-us' : language === 'ru' ? 'ru' : 'hy-am'

    const response = useStaticQuery(graphql`
    query usePrismicexaminatedCases {
       allPrismicExaminatedcases(filter: {tags: {in: "examinated_cases"}}) {
        nodes {
          lang
          data {
            examinatedcasestitle {
              text
            }
            description {
              richText
            }
            service_types {
              type
              type_value
            }
            show_data {
              title {
                text
              }
              description {
                richText
              }
              year
              type
            }
            years {
              year
            }
          }
        }
      }
    }
  `);
    return sanitize(response.allPrismicExaminatedcases.nodes.filter(item => item.lang === newLang)[0].data);
};

const sanitize = (response) => {
    return {
        examinatedCasesTitle: response.examinatedcasestitle.text,
        examinatedCasesDescription: response.description.richText,
        service_types: response.service_types.map(item => ({
            type: item.type,
            type_value: item.type_value
        })),
        options: response.service_types.map(item => item.type_value),
        years: response.years.map(year => year.year),
        show_data: response.show_data.map(data => ({
            title: data.title.text,
            description: data.description.richText,
            year: data.year,
            type: data.type,
        })),

    }
}

export default usePrismicexaminatedCases;