import * as React from 'react'
import { Container, Heading } from '@chakra-ui/react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import Seo from '../../components/SEO'
import Main from '../../components/layouts/Main'
import SmallNav from '../../components/smallNav'
import LegalActs from '../../components/usefulInformation/LegalActs'
import Banks from '../../components/usefulInformation/Banks'
import ExaminatedCases from '../../components/whatToExpect/ExaminatedCases'
import BusinessCirculation from '../../components/usefulInformation/BusinessCirculation'
import DocumentsGrid from '../../components/usefulInformation/DocumentsGrid'
import usePrismicUsefulInformation from "../../hooks/usePrismicUsefulInformation"
import usePrismicFinancialOrganizationsList from '../../hooks/usePrismicFinancialOrganizationsList'
import usePrismicexaminatedCases from "../../hooks/usePrismicexaminatedCases";
const isBrowser = () => typeof window !== "undefined"
const NewsReports = () => {
  const { language } = useTranslation()


  const section = isBrowser() && window.location.hash.replace('#', '');
  const scrollToRef = (offsetTop) => window.scrollTo(0, offsetTop)
  const offsetTop = (ref) => ref.current.offsetTop - 150

  const docTemplatesRef = React.useRef(null)
  const investigateCasesRef = React.useRef(null)
  const cosumaryBusinessRef = React.useRef(null)
  const legalActsRef = React.useRef(null)
  const organizationListRef = React.useRef(null)
  const data = usePrismicUsefulInformation()
  const data1 = usePrismicFinancialOrganizationsList()

  const examinatedCases = usePrismicexaminatedCases()
  React.useEffect(() => {
    if (section === 'document-templates') {
      scrollToRef(offsetTop(docTemplatesRef))
    }
    if (section === 'investigated-cases') {
      scrollToRef(offsetTop(investigateCasesRef))
    }
    if (section === 'cusomary-business') {
      scrollToRef(offsetTop(cosumaryBusinessRef))
    }
    if (section === 'legal-acts') {
      scrollToRef(offsetTop(legalActsRef))
    }
    if (section === 'organizations-list') {
      scrollToRef(offsetTop(organizationListRef))
    }
    
  }, [section])
  return(
    <Main>
      <Container
        px="5"
        maxW="7xl"
        mb="3.75rem">
        <Seo
          title={data.navLink}
          description={data.navLink}
          link={`https://www.fsm.am/${language}/USEFUL INFORMATION`}
        />
          <SmallNav>
            {data.navLink}
          </SmallNav>
        <Heading
          as="h1"
          fontSize={{base: '2xl', sm: "3xl"}}
          fontWeight="900"
          mt={{base: '3', sm: "5"}}
          mb="5rem">
         {data.title}
        </Heading>
        <Container
        px="5"
        mt={{base: '10', sm: "6.25rem"}}
        maxW="7xl">
        <DocumentsGrid ref={docTemplatesRef} data={data} />
        <ExaminatedCases ref={investigateCasesRef} examinatedCases={examinatedCases} />
        <BusinessCirculation ref={cosumaryBusinessRef} data={data} />
        
       
      </Container>

      </Container>
      <LegalActs ref={legalActsRef} data={data} />
      
      <Banks ref={organizationListRef} data={data1} />
    </Main>
  )
}

export default NewsReports

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
